export default {
  message: {
    routeName: {
      wallets: "Carteiras",
      exchange: "Troca",
      swap: "Trocar",
      finance: "Finanças",
      lending: "Empréstimos",
      "crypto Lend": "Staking",
      "cold Wallet": "Carteira fria",
      "account Settings": "Configurações da conta",
      "account Security": "Segurança da conta",
      "notification Settings": "Configurações de notificação",
    },
    login: {
      title: "Login da Conta",
      email: "Digite o Endereço de E-mail",
      password: "Digite sua Senha",
      validation_message: [
        "Certifique-se de fornecer um e-mail válido",
        "A senha deve ter pelo menos 8 caracteres",
      ],
      forgot_password: "Esqueceu a senha?",
      no_account: "Não tem uma conta?",
      create_new: "Criar nova",
      login_button: "Login",
    },
    register: {
      title: "Criar Conta",
      description: "Crie sua conta para começar",
      email_placeholder: "Endereço de E-mail",
      password_placeholder: "Crie uma senha forte",
      email_error_message: "Por favor, insira um e-mail válido",
      password_error_message: "A senha deve ter pelo menos 8 caracteres",
      agree_marketing_label:
        "Concordo em compartilhar dados para fins de marketing.",
      email_updates_label:
        "Concordo em receber atualizações por e-mail da MORIZON EXC",
      submit_button_text: "Próximo",
      submit_button_loading_text: "Carregando...",
      have_account_message: "Já tem uma conta?",
      login_link_text: "Login",
      prompt:
        "Obrigado por se cadastrar no MorizonX. Para garantir que sua conta seja salva e seu login funcione perfeitamente, por favor, complete todo o processo, incluindo o envio do seu documento de identificação. Não fazer isso pode resultar em sua conta não sendo salva e seu login não funcionando corretamente.",
      otpMessage:
        "Você terá 60 segundos para inserir seu OTP. Se não completar dentro do tempo determinado, o código expirará e será necessário enviá-lo novamente.",
    },
    emailVerification: {
      title: "Verificação de E-mail",
      description:
        "Digite o código de verificação de 6 dígitos que foi enviado para {email}. O código é válido por 30 minutos.",
      enterVerificationCode: "Digite o código de verificação",
      resendCodeIn: "Reenviar código em",
      resendCode: "Reenviar código",
      nextButton: "Próximo",
    },
    enterPhoneNumber: {
      title: "Digite o Número de Telefone",
      description: "Forneça seu número de telefone para verificação adicional",
      placeholder: "Número de Telefone",
      nextButton: "Próximo",
    },
    phoneVerification: {
      title: "Verificação de Telefone",
      description:
        "Digite o código de verificação de 6 dígitos que foi enviado para {phone}. O código é válido por 30 minutos.",
      enterVerificationCode: "Digite o código de verificação",
      resendCodeIn: "Reenviar código em",
      resendCode: "Reenviar código",
      nextButton: "Próximo",
    },
    registrationSteps: {
      create_account: "Criar Conta",
      verify_email: "Verificar E-mail",
      phone_number: "Número de Telefone",
      verify_phone: "Verificar Telefone",
    },
    forgotPassword: {
      title: "Esqueci a Senha",
      description: "Digite o e-mail para redefinir a senha",
      placeholder: "Endereço de E-mail",
      validationMessage: "Por favor, insira um e-mail válido",
      goBack: "Voltar",
      nextButton: "Próximo",
    },
    resetOption: {
      title: "Esqueci a Senha",
      description: "Selecione o método de recuperação abaixo.",
      sendToMobile: {
        title: "Enviar para o Celular",
        description: "Envie um código único para o seu número de celular.",
      },
      sendToEmail: {
        title: "Enviar por E-mail",
        description: "Envie um código único para o seu endereço de e-mail.",
      },
      rememberPassword: "Lembrar senha?",
      loginLink: "Login",
    },
    resetPhoneOptions: {
      title: "Esqueci a Senha",
      description: "Digite o número de telefone para redefinir a senha",
      placeholder: "Número de Telefone",
      goBack: "Voltar",
      nextButton: "Próximo",
      rememberPassword: "Lembrar senha?",
      loginLink: "Login",
    },
    resetPassword: {
      title: "Redefinir Senha",
      description: "Escolha uma nova senha para sua conta",
      placeholder: "Criar uma senha forte",
      repeatPasswordPlaceholder: "Repetir senha",
      passwordErrorMessage: "A senha deve ter pelo menos 8 caracteres",
      passwordsDoNotMatchError: "As senhas não coincidem",
      resetButton: "Redefinir",
    },
    otpVerification: {
      title: "Verificação de OTP",
      description:
        "Digite o código de verificação de 6 dígitos que foi enviado para",
      resendCodeIn: "Reenviar código em",
      resendCode: "Reenviar código",
      enterVerificationCode: "Digite o código de verificação",
      nextButton: "Próximo",
    },
    dashboardMenu: {
      wallets: "Carteiras",
      exchange: "Troca",
      swap: "Troca",
      finance: "Finanças",
      cold_wallet: "Carteira Fria",
    },
    lending: {
      p2p: {
        title: "Empréstimo P2P",
        description:
          "Procurando por um empréstimo rápido ou ansioso para obter juros sobre sua criptomoeda? Não procure mais, porque este é o local perfeito!",
      },
      stake: {
        title: "Aposta em Cripto",
        description:
          "Aposte parte de sua criptomoeda e ganhe recompensas de até 14,5% ao ano, especialmente para stablecoins.",
      },
    },
    cold_wallet: {
      understand: "Entenda sobre a Carteira Fria",
      regular: {
        title: "Carteira cripto regular",
        description:
          "Faça transações como trading, troca instantânea, envio e recebimento com sua carteira regular. Proteja seus ativos contra fraude/hackers com a carteira fria.",
      },
      cold: {
        title: "Carteira Fria",
        description:
          "O armazenamento a frio pode ser feito por meio de carteiras de software offline, onde é concluído um processo de autenticação dupla. A chave pública permanece na plataforma online, enquanto um ambiente isolado gera as chaves privadas para segurança ideal.",
      },
      safety: {
        title: "Segurança extra para seus ativos",
        description:
          "A criptomoeda está mais em risco quando armazenada online, tornando o armazenamento a frio a opção ideal para qualquer pessoa que queira preservar com segurança grandes quantidades de criptoativos a longo prazo - desde os Hodlers inabaláveis (investidores que compram e mantêm suas participações, independentemente do preço de mercado) até estabelecimentos que têm milhões de dólares investidos.",
      },
    },
    darkpoolSumarry: [
      "Apenas ordens limitadas são suportadas para Dark Pool.",
      "O tamanho mínimo de ordem para pares BTC é equivalente a aproximadamente 100 mil USD.",
      "O tamanho mínimo de ordem para pares ETH é equivalente a aproximadamente 50 mil USD.",
    ],
    generic: {
      wallets: "Carteiras",
      search: "Buscar",
      pool: "Piscina",
      send: "Enviar",
      receive: "Receber",
      swap: "Trocar",
      description: "Descrição",
      transactions: "Transações",
      history: "Histórico",
      high: "Alto",
      low: "Baixo",
      selected: "Selecionado",
      pair: "Par",
      last_price: "Último Preço",
      volume: "Volume",
      activity: "Atividade",
      balance: "Saldo",
      market_order: "Ordem de Mercado",
      limit_order: "Ordem Limitada",
      buy: "Comprar",
      sell: "Vender",
      amount: "Quantidade",
      price: "Preço",
      set_alert: "Configurar Alerta",
      select: "Selecionar",
      exchange: "Troca",
      from: "De",
      to: "para",
      total: "Total",
      active: "ativo",
      you: "você",
      your: "seu",
      have: "ter",
      lend: "emprestar",
      loan: "empréstimo",
      repay: "pagar",
      lending: "empréstimo",
      closed: "fechado",
      risk: "risco",
      calculate: "calcular",
      rewards: "recompensas",
      staking: "staking",
      yield: "rendimento",
      no: "não",
      stake: "participação",
      previous: "anterior",
      add: "Adicionar",
      account: "Conta",
      documents: "Documento",
      unique: "ID de Usuário Único",
      language: "Idioma",
      withdrawal: "Retirada",
      deposit: "Depósito",
      sending: "Você está enviando",
      authenticator: "Código do Google Authenticator",
      pin: "PIN",
      enter: "Inserir",
      optional: "Opcional",
      network: "Rede",
      fee: "Taxa",
      crypto: "Cripto",
      view_on_blockchain: "Ver Link na Blockchain",
      change: "Mudar",
      portfolio: "Portfólio",
      age: "Idade",
      best: "Melhor",
      asset: "Ativo",
      highest: "Maior",
      date: "Data",
      time: "Hora",
      transaction_type: "Tipo de Transação",
      current_price: "Preço Atual",
      months: "Meses",
      year: "Ano",
      current: "Atual",
      action: "Ação",
      no_transactions: "Sem transações para mostrar",
      filled: "Preenchido",
      other: "Outro",
      trans_activity: "Atividade de Transação",
      dark_pool: "Dark Pool",
      pending: "Pendente",
      completed: "Concluído",
      error: "Ocorreu um erro ao buscar os dados",
      try_again: "Tentar Novamente",
      swap_in_progress: "Troca em Andamento",
      swap_summary:
        "Sua transação está em andamento, levará 30 minutos ou menos para chegar ao seu saldo. Sinta-se à vontade para usar o aplicativo MORIZON EXC enquanto aguarda, e você será notificado após a conclusão da transação.",
      borrow: "Emprestar",
      interest: "Juros",
      rate: "Taxa",
      duration: "Duração",
      repayment: "Pagamento",
      accept_lend:
        "Ao aceitar esta solicitação, concordo com os Termos e Condições de pagamento do empréstimo. A MORIZON EXC não é responsável por esta transação de empréstimo. Não empreste para quem não confia.",
      accept: "Aceitar",
      delete: "Excluir",
      return_in: "Retorno em",
      debt: "Dívida",
      liquidation: "liquidação",
      export: "exportar",
      back: "voltar",
      paid: "pago",
      post: "publicar",
      request: "solicitar",
      agree_post_loan:
        "Ao publicar esta solicitação, concordo com os Termos e Condições de pagamento do empréstimo",
      earn: "ganhar",
      borrower: "mutuário",
      plan: "plano",
      on: "em",
      stable_coins: "Moedas Estáveis",
      new: "novo",
      up: "para cima",
      token: "token",
      term: "prazo",
      per: "por",
      weekly: "semanal",
      for: "para",
      withdraw: "Retirar",
      welcome: "Bem-vindo",
      continue: "Continuar",
      dark_pool: "Piscina escura",
      logout: "Sair",
      security: "Segurança",
      notifications: "Notificações",
      id_card: "Carteira de Identidade",
      proof_of_residence: "Comprovante de Residência",
      general: "Geral",
      two_fa: "Configurações de 2FA",
      password: "Senha",
      create: "Criar",
      repeat: "Repetir",
      same_password: "A nova senha não pode ser igual à senha antiga",
      no_match_password: "Senhas não correspondem",
      coming_soon: "Em breve",
      verified: "Verificada.",
    },
    location: {
      popup:
        "Obrigado pelo seu interesse na bolsa de valores MORIZON EXC. Infelizmente, no momento, não podemos fornecer serviço em seu país. Encorajamos você a verificar regularmente, pois expandimos nossa abrangência e adquirimos novas licenças para atender a mais países.",
    },

    two_fa: {
      recommended: "Recomendado",
      google: "Aplicativo Google Authenticator",
      activate: "Ativar",
      deactivate: "Desativar",
      summary:
        "Proteja sua conta exigindo uma camada adicional de segurança para fazer login.",
      scan_message:
        "Abra seu aplicativo Google Auth e escaneie o código abaixo",
      withdraw: {
        title: "Autenticação de retirada",
        description:
          "Proteja seus fundos usando uma confirmação 2FA sempre que retirar fundos ou gerar um novo endereço de depósito.",
      },
    },
    ai_notifications: {
      title: "Notificações A.I",
      description:
        "Envie notificações push sobre os preços de criptomoedas no mercado com base em seus interesses com algoritmo inteligente.",
    },
    admin_emails: {
      title: "E-mails administrativos",
      description:
        "Atualizações não críticas que podem afetar sua conta Morizon EXC (por exemplo, novos recursos ou serviços, atualizações de políticas, etc.)",
    },
    newsletter_emails: {
      title: "E-mails de newsletter",
      description:
        "Informações úteis que você pode achar interessantes (por exemplo, postagens de blog, análises e fatos interessantes sobre a indústria do Bitcoin, ofertas especiais, etc.)",
    },
    common: {
      on: "ON",
      off: "OFF",
    },
    kyc: {
      kyc_complete_action:
        "Seus documentos KYC aparecerão aqui quando aprovados.",
      kyc_in_progress:
        "Verificação KYC em andamento. Você será notificado quando sua conta for verificada.",
      kyc_not_started:
        "Verifique sua conta aqui. Uma webcam é necessária, você pode fazer isso no seu telefone ou ativar a webcam no seu PC.",
      header: {
        buttonText: "Clique aqui",
        message:
          "para completar a verificação KYC e ativar totalmente sua conta",
      },
      popup:
        "Obrigado por se juntar à MORIZON EXC! As próximas páginas são dedicadas à verificação da sua identidade, permitindo que você utilize totalmente a exchange e negocie com liberdade. Nós nos associamos à ShuftiPro, um provedor de KYC terceirizado confiável, para armazenar com segurança todos os dados pessoais em seus servidores. Caso deseje cancelar sua conta a qualquer momento, entre em contato com a MORIZON EXC pelo e-mail support@nyxex.com, e solicitaremos rapidamente à ShuftiPro a remoção de suas informações de seus servidores. Além disso, garantiremos que todos os seus dados pessoais, exceto os legalmente necessários para retenção, sejam completamente apagados de nossos servidores. Mais uma vez, obrigado por escolher a MORIZON EXC. Estamos ansiosos para atendê-lo!",
      identityVerification: {
        title: "Verifique sua identidade",
        fullName: "Nome completo",
        nameErrorMessage: "O nome deve ter pelo menos 5 caracteres",
        dateOfBirth: "Data de Nascimento",
        address: "Endereço",
        addressErrorMessage: "O endereço deve ter pelo menos 5 caracteres",
        city: "Cidade",
        cityErrorMessage: "A cidade deve ter pelo menos 3 caracteres",
        country: "País",
        currency: "Moeda",
        agreePrivacyPolicy:
          "Concordo com a Política de Privacidade da MORIZON EXC.",
        privacyPolicyLink: "Política de Privacidade",
        submitButton: "Próximo",
        isLoadingText: "Carregando...",
      },
      proofOfResidence: {
        title: "Comprovante de Residência",
        tipsTitle: "Dicas:",
        tips: [
          "Dica 1: Forneça um documento claro e legível",
          "Dica 2: Certifique-se de que o documento mostra seu endereço residencial atual",
          "Dica 3: Evite qualquer brilho ou reflexo no documento",
        ],
        buttonText: "Enviar",
      },
      takePhotoOfID: {
        title: "Tire uma Foto do Seu Documento de Identificação",
        tipsTitle: "Documento Aceito:",
        tips: [
          "Dica 1: Certifique-se de que o documento esteja claro e legível",
          "Dica 2: Capture todo o documento dentro do quadro",
          "Dica 3: Evite reflexos ou brilhos no documento",
        ],
        buttonText: "Enviar",
      },
      takePhotoOfYou: {
        title: "Tire uma foto sua",
        tipsTitle: "Dicas:",
        tips: [
          "Dica 1: Encontre uma área bem iluminada para melhor qualidade de foto.",
          "Dica 2: Remova quaisquer acessórios que possam obstruir seu rosto.",
        ],
        buttonText: "Enviar",
      },
      selfieWithYourID: {
        title: "Selfie com seu documento de identificação",
        tipsTitle: "Dicas:",
        tips: [
          "Dica 1: Certifique-se de que seu rosto e documento de identificação estejam bem iluminados para uma foto nítida.",
          "Dica 2: Remova quaisquer acessórios que possam obstruir seu rosto ou documento de identificação.",
        ],
        buttonText: "Enviar",
      },
      waitingForApproval: {
        title: "Aguardando Aprovação",
        description:
          "Obrigado por escolher a MorizonX para suas necessidades cripto. Sua conta foi registrada e seus documentos foram enviados. Agora você pode fazer login e revisar o painel de câmbio, além de mover criptomoedas de e para suas carteiras. Se estiver interessado em comprar ou vender cripto, por favor clique na opção 'Comprar' ou 'Vender' na guia de câmbio e complete o processo KYC básico para aprovação imediata. Agradecemos mais uma vez e, por favor, não hesite em nos contatar em support@morizonx.com se precisar de mais assistência.",
        closeButton: "Fechar",
      },
    },
    wallet: {
      sendCaution:
        "Aviso: Envie apenas o token especificado para este endereço; o envio de qualquer outro token pode resultar em perda irreversível. Verifique e prossiga com cautela.",
    },

    exchange: {
      prompt: `
    <p>Obrigado por se registrar na Morizon Exchange.</p>
    <p>Para aqueles de vocês que reservaram Morizon Shares, agora podem efetuar o pagamento hoje.</p>
    <p>O preço atual é de £63 por Share, que você precisará converter para $USD usando o conversor de moeda <a href="https://xe.com" target="_blank">https://xe.com</a>.</p>
    <p>O pagamento deve ser feito em USDT para o seguinte endereço de carteira USDT ERC-20:</p>
    <p><strong>0x8DD4F5034D97602aAef43c42bC22269FAF6f356B</strong></p>
    <p>Se você tiver USDT TRC-20, primeiro será necessário convertê-lo para USDT ERC-20 usando um motor de troca como <a href="https://simpleswap.io" target="_blank">https://simpleswap.io</a>.</p>
    <p>Uma vez convertido, você poderá enviar seu pagamento para o endereço de carteira Morizon USDT ERC-20:</p>
    <p><strong>0x8DD4F5034D97602aAef43c42bC22269FAF6f356B</strong></p>
    <p>Por favor, insira seu Nome e Sobrenome na seção 'Nota Pessoal' do 'Formulário de Envio' ao fazer seu pagamento.</p>
    <p>Se precisar de ajuda, envie um e-mail para <a href="mailto:shares@morizonbaank.com">shares@morizonbaank.com</a>.</p>
    <p>Obrigado pelo seu apoio,</p>
    <p>A equipe Morizon</p>
  `,
    },
  },
};
