export default {
  message: {
    routeName: {
      wallets: "Billeteras",
      exchange: "Intercambio",
      swap: "Intercambiar",
      finance: "Finanzas",
      lending: "Préstamos",
      "crypto Lend": "Staking",
      "cold Wallet": "Billetera fría",
      "account Settings": "Configuración de la cuenta",
      "account Security": "Seguridad de la cuenta",
      "notification Settings": "Configuración de notificaciones",
    },
    login: {
      title: "Inicio de sesión en la cuenta",
      email: "Ingrese su dirección de correo electrónico",
      password: "Ingrese su contraseña",
      validation_message: [
        "Asegúrese de proporcionar una dirección de correo electrónico válida",
        "La contraseña debe tener al menos 8 caracteres",
      ],
      forgot_password: "¿Olvidó su contraseña?",
      no_account: "¿No tiene una cuenta?",
      create_new: "Crear una nueva",
      login_button: "Iniciar sesión",
    },
    register: {
      title: "Crear una cuenta",
      description: "Cree su cuenta para comenzar",
      email_placeholder: "Dirección de correo electrónico",
      password_placeholder: "Crea una contraseña segura",
      email_error_message: "Ingrese una dirección de correo electrónico válida",
      password_error_message: "La contraseña debe tener al menos 8 caracteres",
      agree_marketing_label: "Acepto compartir datos con fines de marketing.",
      email_updates_label:
        "Acepto recibir actualizaciones por correo electrónico de MORIZON EXC",
      submit_button_text: "Siguiente",
      submit_button_loading_text: "Cargando...",
      have_account_message: "¿Ya tiene una cuenta?",
      login_link_text: "Iniciar sesión",
      prompt:
        "Gracias por registrarte en MorizonX. Para asegurar que tu cuenta se guarde y tu inicio de sesión funcione sin problemas, por favor completa todo el proceso, incluyendo la carga de tu identificación. No hacerlo puede resultar en que tu cuenta no se guarde y tu inicio de sesión no funcione correctamente.",
      otpMessage:
        "Tendrás 60 segundos para ingresar tu OTP. Si no lo completas dentro del tiempo indicado, el código expirará y deberás volver a enviarlo.",
    },
    emailVerification: {
      title: "Verificación por correo electrónico",
      description:
        "Ingrese el código de verificación de 6 dígitos que se envió a {email}. El código es válido durante 30 minutos.",
      enterVerificationCode: "Ingresar el código de verificación",
      resendCodeIn: "Reenviar el código en",
      resendCode: "Reenviar el código",
      nextButton: "Siguiente",
    },
    enterPhoneNumber: {
      title: "Ingresar número de teléfono",
      description:
        "Proporcione su número de teléfono para una verificación adicional",
      placeholder: "Número de teléfono",
      nextButton: "Siguiente",
    },
    phoneVerification: {
      title: "Verificación por teléfono",
      description:
        "Ingrese el código de verificación de 6 dígitos que se envió a {phone}. El código es válido durante 30 minutos.",
      enterVerificationCode: "Ingresar el código de verificación",
      resendCodeIn: "Reenviar el código en",
      resendCode: "Reenviar el código",
      nextButton: "Siguiente",
    },
    registrationSteps: {
      create_account: "Crear cuenta",
      verify_email: "Verificar correo electrónico",
      phone_number: "Número de teléfono",
      verify_phone: "Verificar teléfono",
    },
    forgotPassword: {
      title: "Olvidé mi contraseña",
      description:
        "Ingrese el correo electrónico para restablecer la contraseña",
      placeholder: "Dirección de correo electrónico",
      validationMessage: "Ingrese una dirección de correo electrónico válida",
      goBack: "Volver",
      nextButton: "Siguiente",
    },
    resetOption: {
      title: "Olvidé mi contraseña",
      description: "Seleccione el método de recuperación a continuación.",
      sendToMobile: {
        title: "Enviar a móvil",
        description: "Enviar un código único a su número de teléfono móvil.",
      },
      sendToEmail: {
        title: "Enviar por correo electrónico",
        description:
          "Enviar un código único a su dirección de correo electrónico.",
      },
      rememberPassword: "¿Recordar contraseña?",
      loginLink: "Iniciar sesión",
    },
    resetPhoneOptions: {
      title: "Olvidé mi contraseña",
      description:
        "Ingrese el número de teléfono para restablecer la contraseña",
      placeholder: "Número de teléfono",
      goBack: "Volver",
      nextButton: "Siguiente",
      rememberPassword: "¿Recordar contraseña?",
      loginLink: "Iniciar sesión",
    },
    resetPassword: {
      title: "Restablecer contraseña",
      description: "Elija una nueva contraseña para su cuenta",
      placeholder: "Crear una contraseña segura",
      repeatPasswordPlaceholder: "Repetir la contraseña",
      passwordErrorMessage: "La contraseña debe tener al menos 8 caracteres",
      passwordsDoNotMatchError: "Las contraseñas no coinciden",
      resetButton: "Restablecer",
    },
    otpVerification: {
      title: "Verificación por OTP",
      description:
        "Ingrese el código de verificación de 6 dígitos que se envió a",
      resendCodeIn: "Reenviar el código en",
      resendCode: "Reenviar el código",
      enterVerificationCode: "Ingresar el código de verificación",
      nextButton: "Siguiente",
    },
    dashboardMenu: {
      wallets: "Billeteras",
      exchange: "Intercambio",
      swap: "Cambiar",
      finance: "Finanzas",
      cold_wallet: "Billetera fría",
    },
    lending: {
      p2p: {
        title: "Préstamo P2P",
        description:
          "¿Buscas un préstamo rápido o quieres ganar intereses en tu criptomoneda? No busques más, ¡este es el lugar ideal!",
      },
      stake: {
        title: "Participación en criptomonedas",
        description:
          "Apuesta parte de tu criptomoneda y gana recompensas de hasta el 14.5% al año, especialmente para stablecoins.",
      },
    },
    cold_wallet: {
      understand: "Comprender la billetera fría",
      regular: {
        title: "Billetera cripto regular",
        description:
          "Realiza todas las transacciones como trading, intercambio instantáneo, envío y recepción con tu billetera regular. Asegura tus activos contra fraudes/piratas informáticos con la billetera fría.",
      },
      cold: {
        title: "Billetera fría",
        description:
          "El almacenamiento en frío se puede realizar a través de billeteras de software sin conexión, donde se realiza un proceso de autenticación doble. La clave pública permanece en la plataforma en línea mientras que un entorno aislado genera las claves privadas para una seguridad óptima.",
      },
      safety: {
        title: "Seguridad adicional para tus activos",
        description:
          "La criptomoneda es más vulnerable cuando se almacena en línea, por lo que el almacenamiento en frío es la opción ideal para quienes desean preservar de manera segura grandes cantidades de criptoactivos a largo plazo, desde Hodlers inquebrantables (inversionistas que compran y mantienen sus activos independientemente del precio del mercado) hasta instituciones con millones de dólares invertidos.",
      },
    },
    darkpoolSumarry: [
      "Solo se admiten órdenes límite para Dark Pool.",
      "El tamaño mínimo de orden para pares de BTC es equivalente a aproximadamente 100,000 USD.",
      "El tamaño mínimo de orden para pares de ETH es equivalente a aproximadamente 50,000 USD.",
    ],
    generic: {
      wallets: "Billeteras",
      search: "Buscar",
      pool: "Piscina",
      send: "Enviar",
      receive: "Recibir",
      swap: "Intercambiar",
      description: "Descripción",
      transactions: "Transacciones",
      history: "Historial",
      high: "Alto",
      low: "Bajo",
      selected: "Seleccionado",
      pair: "Par",
      last_price: "Último Precio",
      volume: "Volumen",
      activity: "Actividad",
      balance: "Saldo",
      market_order: "Orden de Mercado",
      limit_order: "Orden de Límite",
      buy: "Comprar",
      sell: "Vender",
      amount: "Monto",
      price: "Precio",
      set_alert: "Configurar Alerta",
      select: "Seleccionar",
      exchange: "Intercambio",
      from: "Desde",
      to: "hasta",
      total: "Total",
      active: "activo",
      you: "tú",
      your: "tu",
      have: "tener",
      lend: "prestar",
      loan: "préstamo",
      repay: "pagar",
      lending: "préstamo",
      closed: "cerrado",
      risk: "riesgo",
      calculate: "calcular",
      rewards: "recompensas",
      staking: "staking",
      yield: "rendimiento",
      no: "no",
      stake: "participación",
      previous: "anterior",
      add: "Agregar",
      account: "Cuenta",
      documents: "Documento",
      unique: "ID de Usuario Único",
      language: "Idioma",
      withdrawal: "Retiro",
      deposit: "Depósito",
      sending: "Estás enviando",
      authenticator: "Código de Google Authenticator",
      pin: "PIN",
      enter: "Ingresar",
      optional: "Opcional",
      network: "Red",
      fee: "Tarifa",
      crypto: "Cripto",
      view_on_blockchain: "Ver enlace en la cadena de bloques",
      change: "Cambiar",
      portfolio: "Portafolio",
      age: "Edad",
      best: "Mejor",
      asset: "Activo",
      highest: "Más Alto",
      date: "Fecha",
      time: "Hora",
      transaction_type: "Tipo de Transacción",
      current_price: "Precio Actual",
      months: "Meses",
      year: "Año",
      current: "Actual",
      action: "Acción",
      no_transactions: "No hay transacciones para mostrar",
      filled: "Completado",
      other: "Otro",
      trans_activity: "Actividad de Transacción",
      dark_pool: "Dark Pool",
      pending: "Pendiente",
      completed: "Completado",
      error: "Se produjo un error al obtener los datos",
      try_again: "Intentar de Nuevo",
      swap_in_progress: "Intercambio en Progreso",
      swap_summary:
        "Tu transacción está en progreso, tomará 30 minutos o menos para llegar a tu saldo. Siéntete libre de usar la aplicación MORIZON EXC mientras esperas este progreso, y se te notificará una vez que la transacción esté completa.",
      borrow: "Pedir Prestado",
      interest: "Interés",
      rate: "Tasa",
      duration: "Duración",
      repayment: "Reembolso",
      accept_lend:
        "Al aceptar esta solicitud, acepto los Términos y Condiciones de pago del préstamo. MORIZON EXC no es responsable de esta transacción de préstamo. No prestes a quienes no confíes.",
      accept: "Aceptar",
      delete: "Eliminar",
      return_in: "Devolver en",
      debt: "Deuda",
      liquidation: "liquidación",
      export: "exportar",
      back: "atrás",
      paid: "pagado",
      post: "publicar",
      request: "solicitud",
      agree_post_loan:
        "Al publicar esta solicitud, acepto los términos y condiciones de pago del préstamo",
      earn: "ganar",
      borrower: "prestatario",
      plan: "plan",
      on: "en",
      stable_coins: "Monedas Estables",
      new: "nuevo",
      up: "arriba",
      token: "token",
      term: "plazo",
      per: "por",
      weekly: "semanal",
      for: "para",
      withdraw: "Retirar",
      welcome: "Bienvenido",
      continue: "Continuar",
      dark_pool: "Piscina oscura",
      logout: "Cerrar sesión",
      security: "Seguridad",
      notifications: "Notificaciones",
      id_card: "Documento de identidad",
      proof_of_residence: "Comprobante de domicilio",
      general: "General",
      two_fa: "Configuración de 2FA",
      password: "Contraseña",
      create: "Crear",
      repeat: "Repetir",
      same_password:
        "La nueva contraseña no puede ser igual a la contraseña antigua",
      no_match_password: "Las contraseñas no coinciden",
      coming_soon: "Próximamente",
      verified: "Verificada",
    },
    location: {
      popup:
        "Gracias por tu interés en el intercambio MORIZON EXC. Lamentablemente, actualmente no podemos proporcionar servicio en tu país. Te animamos a que vuelvas regularmente mientras expandimos nuestro alcance y obtenemos nuevas licencias para atender a más países.",
    },

    two_fa: {
      recommended: "Recomendado",
      google: "Aplicación Google Authenticator",
      activate: "Activar",
      deactivate: "Desactivar",
      summary:
        "Proteja su cuenta requiriendo una capa adicional de seguridad para iniciar sesión.",
      scan_message:
        "Abra su aplicación Google Auth y escanee el código a continuación",
      withdraw: {
        title: "Autenticación de retiro",
        description:
          "Proteja sus fondos utilizando una confirmación 2FA cada vez que retire fondos o genere una nueva dirección de depósito.",
      },
    },
    ai_notifications: {
      title: "Notificaciones A.I",
      description:
        "Envía notificaciones push sobre los precios de las criptomonedas en el mercado basadas en tus intereses con un algoritmo inteligente.",
    },
    admin_emails: {
      title: "Correos electrónicos administrativos",
      description:
        "Actualizaciones no críticas que pueden afectar tu cuenta en Morizon EXC (por ejemplo, nuevas características o servicios, actualizaciones de políticas, etc.)",
    },
    newsletter_emails: {
      title: "Correos electrónicos de boletines",
      description:
        "Información útil que podrías encontrar interesante (por ejemplo, publicaciones de blog, análisis y datos interesantes sobre la industria del Bitcoin, ofertas especiales, etc.)",
    },
    common: {
      on: "ON",
      off: "OFF",
    },
    kyc: {
      kyc_complete_action:
        "Sus documentos KYC aparecerán aquí cuando sean aprobados.",
      kyc_in_progress:
        "Verificación KYC en progreso. Se le notificará cuando su cuenta haya sido verificada.",
      kyc_not_started:
        "Verifique su cuenta aquí. Se requiere una cámara web, puede hacerlo en su teléfono o habilitar la cámara web en su PC.",
      header: {
        buttonText: "Haz clic aquí",
        message:
          "para completar la verificación KYC y activar completamente tu cuenta",
      },
      popup:
        "¡Gracias por unirte a MORIZON EXC! Las siguientes páginas están dedicadas a verificar tu identidad, permitiéndote utilizar completamente la plataforma de intercambio y comerciar con libertad. Nos hemos asociado con ShuftiPro, un proveedor de KYC de confianza de terceros, para almacenar de manera segura todos los datos personales en sus servidores. Si en algún momento deseas cancelar tu cuenta, por favor contacta a MORIZON EXC en support@nyxex.com, y solicitaremos rápidamente a ShuftiPro que elimine tu información de sus servidores. Además, nos aseguraremos de que todos tus datos personales, excepto aquellos legalmente requeridos para retener, se borren por completo de nuestros servidores. Una vez más, gracias por elegir MORIZON EXC. ¡Esperamos poder servirte!",
      identityVerification: {
        title: "Verifica tu identidad",
        fullName: "Nombre completo",
        nameErrorMessage: "El nombre debe tener al menos 5 caracteres",
        dateOfBirth: "Fecha de nacimiento",
        address: "Dirección",
        addressErrorMessage: "La dirección debe tener al menos 5 caracteres",
        city: "Ciudad",
        cityErrorMessage: "La ciudad debe tener al menos 3 caracteres",
        country: "País",
        currency: "Moneda",
        agreePrivacyPolicy: "Acepto la Política de Privacidad de MORIZON EXC.",
        privacyPolicyLink: "Política de Privacidad",
        submitButton: "Siguiente",
        isLoadingText: "Cargando...",
      },
      proofOfResidence: {
        title: "Comprobante de Domicilio",
        tipsTitle: "Consejos:",
        tips: [
          "Consejo 1: Proporcione un documento claro y legible",
          "Consejo 2: Asegúrese de que el documento muestre su dirección residencial actual",
          "Consejo 3: Evite cualquier reflejo o brillo en el documento",
        ],
        buttonText: "Enviar",
      },
      takePhotoOfID: {
        title: "Tomar foto de tu ID",
        tipsTitle: "Documento aceptado:",
        tips: [
          "Consejo 1: Asegúrate de que el documento esté claro y legible",
          "Consejo 2: Captura toda la ID dentro del marco",
          "Consejo 3: Evita los reflejos o resplandores en el documento",
        ],
        buttonText: "Enviar",
      },
      takePhotoOfYou: {
        title: "Toma una foto tuya",
        tipsTitle: "Consejos:",
        tips: [
          "Consejo 1: Encuentra un área bien iluminada para una mejor calidad de foto.",
          "Consejo 2: Quita cualquier accesorio que pueda obstruir tu rostro.",
        ],
        buttonText: "Enviar",
      },
      selfieWithYourID: {
        title: "Selfie con tu documento de identidad",
        tipsTitle: "Consejos:",
        tips: [
          "Consejo 1: Asegúrate de que tu rostro y tu documento de identidad estén bien iluminados para una foto clara.",
          "Consejo 2: Quita cualquier accesorio que pueda obstruir tu rostro o tu documento de identidad.",
        ],
        buttonText: "Enviar",
      },
      waitingForApproval: {
        title: "Esperando Aprobación",
        description:
          "Gracias por elegir MorizonX para tus necesidades criptográficas. Tu cuenta ha sido registrada y tus documentos han sido cargados. Ahora puedes iniciar sesión y revisar el panel de intercambio, así como mover criptomonedas dentro y fuera de tus billeteras. Si estás interesado en comprar o vender criptomonedas, por favor haz clic en la opción 'Comprar' o 'Vender' en la pestaña de intercambio y completa el proceso KYC básico para aprobación inmediata. Gracias una vez más, y no dudes en contactarnos en support@morizonx.com si necesitas más ayuda.",
        closeButton: "Cerrar",
      },
    },
    wallet: {
      sendCaution:
        "Advertencia: Solo envíe el token especificado a esta dirección; enviar cualquier otro token podría resultar en pérdidas irreversibles. Verifique y proceda con precaución.",
    },

    exchange: {
      prompt: `
    <p>Gracias por registrarte en Morizon Exchange.</p>
    <p>Para aquellos de ustedes que han reservado Morizon Shares, ahora pueden realizar el pago hoy.</p>
    <p>El precio actual es de £63 por Share, que deberán convertir a $USD utilizando el convertidor de moneda <a href="https://xe.com" target="_blank">https://xe.com</a>.</p>
    <p>El pago debe realizarse en USDT a la siguiente dirección de billetera USDT ERC-20:</p>
    <p><strong>0x8DD4F5034D97602aAef43c42bC22269FAF6f356B</strong></p>
    <p>Si tienen USDT TRC-20, primero deberán convertirlo a USDT ERC-20 utilizando un motor de intercambio como <a href="https://simpleswap.io" target="_blank">https://simpleswap.io</a>.</p>
    <p>Una vez convertido, podrán enviar su pago a la dirección de billetera Morizon USDT ERC-20:</p>
    <p><strong>0x8DD4F5034D97602aAef43c42bC22269FAF6f356B</strong></p>
    <p>Por favor, inserten su Nombre y Apellido en la sección 'Nota Personal' del 'Formulario de Envío' al realizar su pago.</p>
    <p>Si necesitan ayuda, por favor envíen un correo electrónico a <a href="mailto:shares@morizonbaank.com">shares@morizonbaank.com</a>.</p>
    <p>Gracias por su apoyo,</p>
    <p>El equipo de Morizon</p>
  `,
    },
  },
};
